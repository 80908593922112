@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #191c1f;
  color: "#fff";
}

html {
  @apply text-gray-50;
}

body::-webkit-scrollbar {
  width: 8px; /* Adjust the width as desired */
}

body::-webkit-scrollbar-thumb {
  background-color: #191c1f; /* Change to your desired color */
}

body::-webkit-scrollbar-track {
  background-color: #191c1f; /* Change to the same background color as your body element */
}

body::-webkit-scrollbar {
  display: none;
}
